import { json, LoaderFunctionArgs, MetaFunction } from '@remix-run/cloudflare';
import { ContentLayout } from '~/components/admin-panel/content-layout';
import PlaceholderContent from '~/components/placeholder-content';
import { RouteHandle } from '~/lib/route-handle';
import { getSeoMeta } from '~/lib/seo';
import { getAdminUserService } from '~/lib/user-service.server';

export const handle: RouteHandle = {
  breadcrumbText: 'Welcome',
};

export const loader = async (args: LoaderFunctionArgs) => {
  await getAdminUserService(args, { redirectIfLoggedOut: true, redirectIfNotAdmin: true });
  return json({});
};

export const meta: MetaFunction = (args) => {
  return getSeoMeta(
    {
      title: 'Courses Admin',
    },
    args
  );
};

export default function IndexPage() {
  return (
    <>
      <ContentLayout title="Welcome">
        <PlaceholderContent />
      </ContentLayout>
    </>
  );
}
